// AuthCallback.js
import React, { useEffect, useState } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';

const AuthCallback = () => {
  const { getIdTokenClaims } = Auth0Client();
  /* eslint-disable */
  const [userEmail, setUserEmail] = useState('');
  /* eslint-enable */

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const idTokenClaims = await getIdTokenClaims();
        if (idTokenClaims && idTokenClaims.email) {
          setUserEmail(idTokenClaims.email);
        }
      } catch (error) {
        console.error('Error fetching user email:', error);
      }
    };

    fetchUserEmail();
  }, [getIdTokenClaims]);

  return (
    <div>
      {/* You can add a loading spinner or any message here while handling the callback */}
      <h1>Auth Callback</h1>
      <p>Loading...</p>
    </div>
  );
};

export default AuthCallback;

import React from 'react'
import{List, Datagrid, TextField} from 'react-admin'


function devices(props) {
  return (
    <List { ...props}>
        <Datagrid>
            <TextField source='id' />
            <TextField source='user_id' />
            <TextField source='name' />
            <TextField source='phone' />
            <TextField source='pin' />
            <TextField source='line1' />
            <TextField source='line2 ' />
            <TextField source='city' />
            <TextField source='state' />
            <TextField source='zip' />
            <TextField source='imei' />
            <TextField source='imei_type' />
            <TextField source='imei_active' />
            <TextField source='subscription_active' />
            <TextField source='' />
        </Datagrid>
    </List>

  )
}

export default devices
import React from 'react'
import{List, Datagrid, TextField, Filter, SearchInput} from 'react-admin'


const CustomerFilter = (props)=>(<Filter {...props}>
  <SearchInput placeholder='Customer Email' source='owner_email'
  resettable />
</Filter>)

function customerList(props) {
  return (
    <List { ...props} filters={<CustomerFilter/>} >
        <Datagrid>
            <TextField source='id' />
            <TextField source='owner_name' />
            <TextField source='owner_phone' />
            <TextField source='owner_email' />
            <TextField source='active' />
        </Datagrid>
    </List>

  )
}

export default customerList
import * as React from 'react'; 
import { fetchUtils, Admin, Resource, CustomRoutes } from 'react-admin';
import { BrowserRouter, Route } from 'react-router-dom';
import { Auth0AuthProvider } from 'ra-auth-auth0';
import { Auth0Client } from '@auth0/auth0-spa-js';
import simpleRestProvider from 'ra-data-simple-rest';
import customerList from './componets/customerList';
import alarms from './componets/alarms';
import devices from './componets/devices'; //test
import AuthCallback from './AuthCallback'; // Create AuthCallback.js component


const auth0 = new Auth0Client({
  domain: "wsper.us.auth0.com",
  clientId: "bzQW3DGph1xfXL7ML3bXiBheo7DRPFY9",
  cacheLocation: 'localstorage',
  authorizationParams: {
      audience: "https://portal.getwsper.com",
  },
});

const authProvider = Auth0AuthProvider(auth0, {
  loginRedirectUri: "https://portal.getwsper.com/auth-callback",
  logoutRedirectUri: "https://getwsper.com",
});

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ "Content-Type": 'text/html' });
  }
  // add your own headers here
  options.headers.set('Access-Control-Expose-Headers', 'X-Total-Count'); //<----see here
  options.headers.set("x-requested-with", "XMLHttpRequest");
  return fetchUtils.fetchJson(url, options);
};

/* eslint-disable */
const dataProvider = simpleRestProvider('https://api.getwsper.com/v1',httpClient);
/* eslint-enable */
const App = () => (
  <BrowserRouter>
    <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}>
            <Resource name="users" list={customerList}/>
            <Resource name="devices" list={devices}/>
            <Resource name="alarms" list={alarms}/>
      </Admin>
      <CustomRoutes>
        <Route exact path="/auth-callback" component={AuthCallback} />
      </CustomRoutes>
    </BrowserRouter>
);

export default App;

import * as React from "react";
import{List, Datagrid, TextField, DateField} from 'react-admin'

//add disableSyncWithLocation to the <List disableSyncWithLocation>

function alarms(props) {
    return(
    <List>
        <Datagrid>
            <TextField source='id' />
            <DateField source='created_at' />
            <TextField source='imei' />
            <TextField source='msg_type' />
            <TextField source='batt_vol' />
            <TextField source='temp_cur' />
            <TextField source='detect_type' />
        </Datagrid>
    </List>
    )
}


export default alarms